import { __, _x } from "@wordpress/i18n";
import { useState, useEffect, useMemo } from "@wordpress/element";
import { findFilterText } from "../utils";
import { ReactComponent as TypeIcon } from "../../material/icons/goland_type_icon.svg";
import { ReactComponent as AreaIcon } from "../../material/icons/goland_area_icon.svg";
import { ReactComponent as CountryIcon } from "../../material/icons/goland_country_icon.svg";
import { ReactComponent as MunicipalityIcon } from "../../material/icons/goland_municipality_icon.svg";
import { Flex } from "@wordpress/components";
import { floor, flow, sortBy } from "lodash";
import { replaceNordicCharacters } from "../utils";
export default function Block({
	showCountryFilter,
	showRegionFilter,
	showTypeFilter,
	showAreaFilter,
	backgroundColor,
	borderColor,
	borderThickness,
	borderRadius,
	padding,
	headingColor,
	selectColor,
	selectColorHover,
	selectBorderColor,
	selectBorderThickness,
	selectBorderRadius,
	selectBackgroundColor,
	blockTitle,
	blockSubtitle,
	areaRanges,
	iconColor,
	iconBackgroundColor,
	customSelectOptions,
	customIcons,
	iconSize,
	button1,
	button2,
	button1Styles,
	button2Styles,
	button1View,
	button2View,
	sortedCustomSelectOptions,
	showIcons
}) {
	const data = goland.all_ads;

	const [filters, setFilters] = useState({});
	const [regions, setRegions] = useState();
	const [countries, setCountries] = useState();
	const [types, setTypes] = useState();
	const [adsList, setAdsList] = useState([]);
	const [viewFilters, setViewFilters] = useState(true);
	const [showTypeDropdown, setShowTypeDropdown] = useState(false);
	const [showAreaDropdown, setShowAreaDropdown] = useState(false);
	const [showRegionDropdown, setShowRegionDropdown] = useState(false);
	const [showCountryDropdown, setShowCountryDropdown] = useState(false);

	// Add default value on page load
	useEffect(() => {
		setAdsList(data);
	}, []);

	// Create the filter dropdown options
	useEffect(() => {
		let regionsArray = [];
		let countryArray = [];
		let typeArray = [];
		customSelectOptions = sortBy(customSelectOptions, function (o) {
			return o.name;
		});
		if (data) {
			data.forEach((ad) => {
				let type;
				if (ad?.address.municipality)
					regionsArray.push(ad.address.municipality);
				if (ad?.address.country) countryArray.push(ad.address.country);
				if (ad?.main_type)
					type = goland.filters.premises_main_types.find(
						(type) => type.key === ad.main_type
					);
				typeArray.push(type.text);
			});
			regionsArray = sortBy(regionsArray, function (o) {
				return o;
			});
			countryArray = sortBy(countryArray, function (o) {
				return o;
			});
			typeArray = sortBy(typeArray, function (o) {
				return o;
			});
			setRegions([...new Set(regionsArray)]);
			setCountries([...new Set(countryArray)]);
			setTypes([...new Set(typeArray)]);
		}
	}, [customSelectOptions]);

	function handleCountryChange(value) {
		setFilters({ ...filters, country: value });
	}
	function handleRegionChange(value) {
		if (typeof value === "object") {
			setFilters({ ...filters, region: value.name });
		} else {
			setFilters({ ...filters, region: value });
		}
	}
	function handleTypeChange(value) {
		setFilters({ ...filters, type: value });
	}
	function handleAreaChange(value) {
		if (!value) {
			setFilters({ ...filters, area: null });
		} else {
			setFilters({ ...filters, area: { min: value.min, max: value.max } });
		}
	}

	const blockStyles = {
		backgroundColor,
		border: `solid ${borderThickness}px ${borderColor}`,
		borderRadius: borderRadius + "px",
		padding: padding + "px",
	};

	const headingStyles = {
		color: headingColor,
	};

	const selectStyles = `
		.wp-block-goland-features-frontpage-lift select,
		.wp-block-goland-features-frontpage-lift .custom-select,
		.wp-block-goland-features-frontpage-lift .dropdown {
			color: ${selectColor};
			border: solid ${selectBorderThickness}px ${selectBorderColor};
			border-radius: ${selectBorderRadius}px;
			background-color: ${selectBackgroundColor}
		}
		.wp-block-goland-features-frontpage-lift .dropdown span:hover {
			color: ${selectColorHover};
		}
		.wp-block-goland-features-frontpage-lift .single-filter svg,
		.wp-block-goland-features-frontpage-lift .single-filter svg > *,
		.wp-block-goland-features-frontpage-lift .single-filter img
		{
			stroke: ${iconColor} !important;
			fill: ${iconBackgroundColor} !important;
			${!showIcons && "display: none;"}
		}
		.wp-block-goland-features-frontpage-lift .button1:hover {
			color: ${button1Styles.hoverColor} !important;
			background-color: ${button1Styles.hoverBackgroundColor} !important;
			border-color: ${button1Styles.hoverBorderColor} !important;
		}
		.wp-block-goland-features-frontpage-lift .button2:hover {
			color: ${button2Styles.hoverColor} !important;
			background-color: ${button2Styles.hoverBackgroundColor} !important;
			border-color: ${button2Styles.hoverBorderColor} !important;
		}
	`;

	// Construct the button href from the selected filters
	const buttonHref = replaceNordicCharacters(`${goland.pages.ad.listing}${filters.region ? `${filters.region}/` : ""
		}
	?${filters.country ? `&country=${filters.country}` : ""}${filters.type ? `&type=${filters.type}` : ""
		}${filters.area
			? `&area-min=${filters.area.min}&area-max=${filters.area.max}`
			: ""
		}`);

	return (
		<>
			<style>{selectStyles}</style>
			<div className="filters-container" style={blockStyles}>
				<div className="block-title-container" style={headingStyles}>
					<h2 className="block-title">{blockTitle}</h2>
					<p>{blockSubtitle}</p>
				</div>
				<div
					className="filter-selects"
					style={!viewFilters ? { display: "none" } : {}}
				>
					{
						// Check because boolean turns to string on frontend for some reason
						(showCountryFilter == 1 || showCountryFilter == "true") &&
						countries && (
							<div className="single-filter country">
								<div className="label-and-icon">
									{customIcons && customIcons.country ? (
										<img
											src={customIcons.country}
											width={iconSize}
											height={iconSize}
										/>
									) : (
										<CountryIcon />
									)}
									<label htmlFor="country-select">
										{__("Country", "goland-features")}
									</label>
								</div>
								<div
									className={`custom-select-wrapper ${showCountryDropdown ? "active" : ""
										}`}
									tabIndex="0"
									onClick={() => setShowCountryDropdown(!showCountryDropdown)}
									onBlur={() => setShowCountryDropdown(false)}
									id="country-select"
								>
									<div className="custom-select">
										{filters.country || __("All", "goland-features")}
									</div>
									{showCountryDropdown && (
										<div className="dropdown">
											<span
												className="custom-option"
												onClick={() => {
													handleCountryChange("");
													setShowCountryDropdown(false);
												}}
											>
												{__("All", "goland-features")}
											</span>
											{countries &&
												countries.map((country) => {
													return (
														<span
															className="custom-option"
															key={country}
															onClick={() => {
																handleCountryChange(country);
																setShowCountryDropdown(false);
															}}
														>
															{country}
														</span>
													);
												})}
										</div>
									)}
								</div>
							</div>
						)
					}
					{(showRegionFilter == 1 || showRegionFilter == "true") && (
						<div className="single-filter region">
							<div className="label-and-icon">
								{customIcons && customIcons.region ? (
									<img
										src={customIcons.region}
										width={iconSize}
										height={iconSize}
									/>
								) : (
									<MunicipalityIcon />
								)}
								<label htmlFor="region-select">
									{_x("Municipality/location", "Frontpage region filter", "goland-features")}
								</label>
							</div>
							<div
								className={`custom-select-wrapper ${showRegionDropdown ? "active" : ""
									}`}
								tabIndex="0"
								onClick={() => setShowRegionDropdown(!showRegionDropdown)}
								onBlur={() => setShowRegionDropdown(false)}
								id="region-select"
							>
								<div className="custom-select">
									{filters.region || __("All", "goland-features")}
								</div>
								{showRegionDropdown && (
									<div className="dropdown">
										<span
											className="custom-option"
											onClick={() => {
												handleRegionChange("");
												setShowRegionDropdown(false);
											}}
										>
											{__("All", "goland-features")}
										</span>
										{sortedCustomSelectOptions
											? sortedCustomSelectOptions.length > 0 &&
											sortedCustomSelectOptions.map((customOption) => {
												return (
													<span
														className="custom-option"
														key={customOption.name}
														onClick={() => {
															handleRegionChange(customOption);
														}}
													>
														{customOption.name}
													</span>
												);
											})
											: customSelectOptions.length > 0 &&
											customSelectOptions.map((customOption) => {
												return (
													<span
														className="custom-option"
														key={customOption.name}
														onClick={() => {
															handleRegionChange(customOption);
														}}
													>
														{customOption.name}
													</span>
												);
											})}
										{regions &&
											regions.map((region) => {
												return (
													<span
														className="custom-option"
														key={region}
														onClick={() => {
															handleRegionChange(region);
															setShowRegionDropdown(false);
														}}
													>
														{region}
													</span>
												);
											})}
									</div>
								)}
							</div>
						</div>
					)}
					{(showTypeFilter == 1 || showTypeFilter == "true") && (
						<div className="single-filter type">
							<div className="label-and-icon">
								{customIcons && customIcons.type ? (
									<img
										src={customIcons.type}
										width={iconSize}
										height={iconSize}
									/>
								) : (
									<TypeIcon />
								)}
								<label htmlFor="type-select">{__("Type", "goland-features")}</label>
							</div>
							<div
								className={`custom-select-wrapper ${showTypeDropdown ? "active" : ""
									}`}
								tabIndex="0"
								onClick={() => setShowTypeDropdown(!showTypeDropdown)}
								onBlur={() => setShowTypeDropdown(false)}
								id="type-select"
							>
								<div className="custom-select">
									<span style={{display: 'block'}}>
									{findFilterText(
										goland.filters,
										"premises_main_types",
										filters.type
									) || __("All", "goland-features")}
									</span>
								</div>
								{showTypeDropdown && (
									<div className="dropdown">
										<span
											className="custom-option"
											onClick={() => {
												handleTypeChange("");
												setShowTypeDropdown(false);
											}}
										>
											{__("All", "goland-features")}
										</span>
										{types &&
											types.map((type) => {
												const typeObject =
													goland.filters.premises_main_types.find(
														(filterType) => filterType.text === type
													);
												return (
													<span
														className="custom-option"
														key={type}

														onClick={() => {
															handleTypeChange(typeObject.key);
															setShowTypeDropdown(false);
														}}
													>
														{typeObject.text}
													</span>
												);
											})}
									</div>
								)}
							</div>
						</div>
					)}
					{(showAreaFilter || showAreaFilter === "true") && (
						<div className="single-filter area">
							<div className="label-and-icon">
								{customIcons && customIcons.area ? (
									<img
										src={customIcons.area}
										width={iconSize}
										height={iconSize}
									/>
								) : (
									<AreaIcon />
								)}
								<label htmlFor="area-select">{__("Area", "goland-features")}</label>
							</div>
							<div
								className={`custom-select-wrapper ${showAreaDropdown ? "active" : ""
									}`}
								tabIndex="0"
								onClick={() => setShowAreaDropdown(!showAreaDropdown)}
								onBlur={() => setShowAreaDropdown(false)}
								id="area-select"
							>
								<div className="custom-select">
									{(filters.area &&
										`${filters.area.min} - ${filters.area.max} ${goland.units.measurement.area_text}`) ||
										__("All", "goland-features")}
								</div>
								{showAreaDropdown && (
									<div className="dropdown">
										<span
											className="custom-option"
											onClick={() => {
												handleAreaChange("");
												setShowAreaDropdown(false);
											}}
										>
											{__("All", "goland-features")}
										</span>
										{areaRanges &&
											areaRanges.map((value, index) => {
												return (
													<span
														className="custom-option"
														key={index}
														onClick={() => {
															handleAreaChange(value);
															setShowAreaDropdown(false);
														}}
													>
														{value.min} - {value.max} {goland.units.measurement.area_text}
													</span>
												);
											})}
									</div>
								)}
							</div>
						</div>
					)}
				</div>
				<div
					style={{
						display: "flex",
						flexFlow: "row no-wrap",
						justifyContent: "center",
						gap: "2rem",
					}}
				>
					{button1 && (
						<a
							className="btn button1"
							href={`${buttonHref}&view=${button1View}`}
							style={{
								color: button1Styles.color,
								backgroundColor: button1Styles.backgroundColor,
								borderRadius: `${button1Styles.borderRadius}px`,
								borderWidth: `${button1Styles.borderThickness}px`,
								borderColor: `${button1Styles.borderColor}`
							}}
						>
							{button1}
						</a>
					)}
					{button2 && (
						<a
							className="btn button2"
							href={`${buttonHref}&view=${button2View}`}
							style={{
								color: button2Styles.color,
								backgroundColor: button2Styles.backgroundColor,
								borderRadius: `${button2Styles.borderRadius}px`,
								borderWidth: `${button2Styles.borderThickness}px`,
								borderColor: `${button2Styles.borderColor}`
							}}
						>
							{button2}
						</a>
					)}
				</div>
			</div>
		</>
	);
}
